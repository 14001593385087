<template>
  <v-card>
      <!-- <v-card-title>Console</v-card-title> -->

      <div class="console">
          <div v-for="his in history" :key="his[0]">
              <span class="console-time">[{{parseDate(his[0])}}]</span>

              <span class="console-recv" v-if="!his[1]">(RECV)</span>
              <span class="console-send" v-else>(SEND)</span>

              <span class="console-text">: {{his[2]}}</span>
          </div>
      </div>
      
      <v-card-actions>
          <v-container style="margin:0px;padding:0px">
              <v-row no-gutters>
                  <v-col cols=8>
                    <v-text-field dense label="输入指令" v-model="cmd_text" @keyup.enter="send"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-btn block @click="send" text>发送</v-btn>
                  </v-col>
              </v-row>
          </v-container>
          
      </v-card-actions>
  </v-card>
</template>

<script>
export default {
    props:{
        history:Array
    },
    data:()=>({
        cmd_text:"",
    }),
    methods:{
        parseDate:function(time){
            var date = new Date(time)
            var hour = ("0" + date.getHours()).slice(-2)
            var minute = ("0" + date.getMinutes()).slice(-2)
            var secound = ("0" + date.getSeconds()).slice(-2)

            return `${hour}:${minute}:${secound}`
        },
        send: function(){
            if(this.cmd_text == ""){
                return
            }
            this.$emit("send",this.cmd_text)
            this.cmd_text = ""
        }
    }
}
</script>

<style>
.console{
    height: 500px;
    background:rgb(0, 0, 0);
    padding:10px;
    overflow-y: scroll;
    /* color:white; */
}

.console-time{
    color: rgb(197, 134, 192);
}

.console-send{
    color: greenyellow;
}

.console-recv{
    color: aqua;
}

.console-text{
    color: white;
}
</style>