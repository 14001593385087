<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-img :src="require('@/assets/test.jpg')" width="188px" height="120px"/>
        </v-col>
        <v-col cols="6">
          <v-img :src="require('@/assets/test.jpg')" width="188px" height="120px"/>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text>
      当前阈值：{{threshold}}，剩余展示张数：{{picreq}}。
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props:{
    imgdata:String
  },
  data: ()=>({
    threshold:0,
    picreq:0,
    encoder:null
  }),
  watch:{
    imgdata:function(){
      let img_segments = this.imgdata.split(",")
      this.threshold = img_segments[0]
      this.picreq = img_segments[1]
      let img_real_data = this.encoder.encode(img_segments[2])
      console.log(img_real_data)
    }
  },
  mounted:function(){
    this.encoder = new window.TextEncoder()
  }
}
</script>

<style>

</style>