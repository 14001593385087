<template>
  <div class="highlight-container" :style="do_anim?'animation-name: alert;':''">
      <div class="highlight-data">
          {{hlvar.varvalue}}
      </div>
      <div>
          {{hlvar.varname}}
      </div>
  </div>
</template>

<script>
export default {
    data: ()=>({
        last_var:null,
        do_anim:false,
    }),
    props:["hlvar"],
    watch:{
        hlvar: function(){
            if(this.hlvar.varvalue != this.last_var){
                this.do_anim = false
                this.$nextTick(()=>{
                    this.do_anim = true
                })
            }
            this.last_var = this.hlvar.varvalue
        }
    }
}
</script>

<style>
.highlight-container{
    /* width: 100%;
    height: 120px; */
    padding: 15px;
    text-align: center;
    animation-duration: 0.5s;
}

.highlight-data{
    font-size: 68px;
    line-height: 68px;
}

@keyframes alert {
  from {
  }

  50% {
    color:white;
    background-color: purple;
  }

  to {
  }
}
</style>