<template>
  <div style="margin:0px;padding:0px">
    <v-btn text @click="show_import"> 导入配置 </v-btn>
    <v-btn text @click="show_export"> 导出配置 </v-btn>
    <v-btn text @click="reset"> 重置配置 </v-btn>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-textarea :label="isimport?'请将导出的配置粘贴到这里':'下方是你导出的配置。'" 
        :readonly="!isimport"
        dense style="padding:20px 20px 0px 20px;"
        v-model="text"></v-textarea>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> 取消 </v-btn>
          <v-btn color="primary" text @click="import_config" v-if="isimport"> 确定 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const {Base64} = require('js-base64');

export default {
  data: () => ({
    dialog: false,
    isimport:false,
    text:""
  }),
  methods:{
      import_config:function(){
          var jsonobj = JSON.parse(Base64.decode(this.text))
          window.localStorage.setItem("connect_args",jsonobj.conn)
          window.localStorage.setItem("control_args",jsonobj.ctrl)
          location.reload()
      },
      show_export:function(){
          var conn = window.localStorage.getItem("connect_args")
          var ctrl = window.localStorage.getItem("control_args")
          var jsontext = JSON.stringify({conn,ctrl})
          this.text = Base64.encode(jsontext)
          this.isimport = false
          this.dialog = true
      },
      show_import:function(){
          this.text = ""
          this.isimport = true
          this.dialog = true
      },
      reset: function(){
          window.localStorage.clear()
          location.reload()
      }
  }
};
</script>

<style>
</style>