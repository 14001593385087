<template>
  <v-card>
    <!-- <v-card-title>Control</v-card-title> -->

    <div v-if="args.length == 0" class="empty"> 目前暂时没有参数哦。要不按右下角的按钮编辑一下？</div>
    <v-container fluid style="padding-top: 0px; padding-bottom: 0px">
      <v-row v-for="(args, idx) in getTrips(args)" :key="'trips' + idx">
        <v-col cols="4" v-for="(arg, idx) in args" :key="'single' + idx">
          <v-text-field
            dense
            :label="arg.varname + '(' + arg.opcode + ')'"
            v-model="arg.data"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <div v-if="operations.length == 0" class="empty"> 目前暂时没有操作哦。要不按右下角的按钮编辑一下？</div>
    <v-container fluid v-else>
      <v-row v-for="(args, idx) in getTrips(operations)" :key="'op-trips' + idx">
        <v-col cols="4" v-for="(arg, idx) in args" :key="'op-single' + idx">
          <v-btn block @click="do_operate(arg.opcode)">
            {{arg.opname + '(' + arg.opcode + ')'}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="upload_args" color="primary">上传</v-btn>
      <v-btn @click="import_args" color="primary">导入</v-btn>
      <v-btn @click="clear_args" color="error darken-1">清空</v-btn>
      <v-btn @click="dialog = true">编辑</v-btn>
    </v-card-actions>



    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          配置控制区域 
          <v-spacer></v-spacer>

          <v-menu
            v-model="menu_var"
            :close-on-content-click="false"
            :nudge-width="120"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="indigo"
                dark
                v-bind="attrs"
                v-on="on"
              >
                新增变量
              </v-btn>
            </template>
            <v-card @keydown.enter="new_var">
              <v-card-text>
                <v-text-field dense label="变量名" v-model="varname"></v-text-field>
                <v-text-field dense label="操作码" v-model="var_opcode"></v-text-field>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="new_var" color="primary">添加</v-btn>
                <v-btn @click="menu_var = false">关闭</v-btn>
              </v-card-actions> 
            </v-card>
          </v-menu>
          <v-menu
            v-model="menu_ops"
            :close-on-content-click="false"
            :nudge-width="120"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="indigo"
                dark
                v-bind="attrs"
                v-on="on"
              >
                新增操作
              </v-btn>
            </template>
            <v-card @keydown.enter="new_op">
              <v-card-text>
                <v-text-field dense label="操作名" v-model="opname"></v-text-field>
                <v-text-field dense label="操作码" v-model="op_opcode"></v-text-field>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="new_op" color="primary">添加</v-btn>
                <v-btn @click="menu_ops = false">关闭</v-btn>
              </v-card-actions> 
            </v-card>
          </v-menu>

          <v-spacer></v-spacer>
          <v-icon @click="dialog = !dialog">mdi-close</v-icon>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
             <v-data-table
                :headers="args_header"
                :items="args"
                :items-per-page="10"
                class="elevation-1"
                dense
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    @click="deletevar(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
             <v-data-table
                :headers="ops_header"
                :items="operations"
                :items-per-page="10"
                class="elevation-1"
                dense
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    @click="deleteop(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        
        <v-divider></v-divider>

      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: {
    vars: Object,
  },
  data: () => ({
    args: [], //[{ opcode: "A", varname: "servop", data: null }],
    operations: [], //[{ opcode: "R", opname: "重置"}],
    dialog: false,
    menu_var: false,
    menu_ops: false,

    args_header: [
      { text: "变量名", value: "varname" },
      { text: "操作码", value: "opcode" },
      { text: "删除", value: "actions", sortable: false },
    ],
    ops_header: [
      { text: "操作名", value: "opname" },
      { text: "操作码", value: "opcode" },
      { text: "删除", value: "actions", sortable: false },
    ],

    varname:"",
    var_opcode:"",

    opname:"",
    op_opcode:"",
  }),
  methods: {
    clear_args: function(){
      for (let index = 0; index < this.args.length; index++) {
        const element = this.args[index];
        element.data = ""
      }
    },
    import_args: function(){
      var var_keys = Object.keys(this.vars);
      for (let index = 0; index < this.args.length; index++) {
        var arg = this.args[index];
        if(var_keys.includes(arg.varname)){
          arg.data = this.vars[arg.varname]
        }
      }
    },
    editItem: function (item) {
      console.log(item);
    },
    new_var: function(){
      if(this.var_opcode == "" || this.varname == "")
        return
      this.args.push({opcode:this.var_opcode,varname:this.varname,value:null})
      this.var_opcode = ""
      this.varname = ""
      // this.menu_var = false
      this.save()
    },
    new_op: function(){
      if(this.op_opcode == "" || this.opname == "")
        return
      this.operations.push({opcode:this.op_opcode,opname:this.opname})
      this.op_opcode = ""
      this.opname = ""
      // this.menu_ops = false
      this.save()
    },
    deleteop: function (item) {
      let index = this.operations.indexOf(item);
      this.operations.splice(index, 1);
      this.save();
    },
    deletevar: function (item) {
      let index = this.args.indexOf(item);
      this.args.splice(index, 1);
      this.save();
    },
    getTrips: function (arr) {
      let result = [],
        tmp = [];
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        tmp.push(element);
        if (tmp.length == 3) {
          result.push(tmp);
          tmp = [];
        }
      }
      if (tmp.length != 0) {
        result.push(tmp);
      }

      return result;
    },
    upload_args: function () {
      var msg = "";
      this.args.forEach((arg) => {
        if (arg.data != null && arg.data != "") {
          msg += arg.opcode + arg.data + "|";
        }
      });
      if (msg != "") {
        this.$emit("write", msg);
      }
    },
    do_operate: function (opcode) {
      this.$emit("write", opcode + "|");
    },
    save: function () {
      var data = { arg: this.args, operations: this.operations };
      var json = JSON.stringify(data);
      window.localStorage.setItem("control_args", json);
    },
  },
  mounted: function () {
    let json = window.localStorage.getItem("control_args");
    if (json != null) {
      let arg_and_op = JSON.parse(json);
      this.args = arg_and_op.arg;
      this.operations = arg_and_op.operations;
    }
  },
  watch: {
  },
};
</script>

<style>
.empty{
  font-size: 80%;
  text-align: center;
  padding: 10px;
  color:gray
}
</style>