<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      
    >
      <v-app-bar-title>
        <v-icon>fas fa-car-crash</v-icon>
        智能(碰碰)车
      </v-app-bar-title>
      
      <v-spacer></v-spacer>
      <v-btn :color="port_status?'success':'error'" @click="connect_btn()"> 
         {{port_status?'已连接':'连接'}}
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12">
                <Picture :imgdata="image_data"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <carcontrol 
                  @write="send_message"
                  :vars="vars_dict"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="3"> 
            <v-row>
              <v-col cols="12">
                <varibles :var_msg="var_msg" @update="update_var"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-row>
              <v-col cols="12">
                <btconsole 
                  :history="message_history"
                  @send="send_message"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 sm=12 md=4>
            <div style="">
              <div>
                <div style="font-size:150%;">白银的工具箱</div>
                ©2021 shirogane.All rights reserved. <br/>
                联系方式:inapp@iccmc.cc <br/>
                特别感谢：莫斯提马<br/>
              </div>
              <a href="https://beian.miit.gov.cn/" target="_blank">桂ICP备2021004005号-1</a> 
              <div style="width:300px;">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010202000531" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img :src="require('@/assets/ga_beian.png')" style="float:left;"/>
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">桂公网安备 45010202000531号</p>
                </a>
              </div>
            </div>
            <datatransfer />
          </v-col>
          <v-col cols=12 sm=12 md=8>
            <a href="https://gitee.com/exusia1/smartcar_fe">
              <v-img src="https://gitee.com/exusia1/smartcar_fe/widgets/widget_card.svg?colors=4183c4,ffffff,ffffff,e3e9ed,666666,9b9b9b" />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-main>


    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
    >
      {{snackbar_text}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          关闭
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          设定连接参数
        </v-card-title>

        <v-card-text>
          <v-select 
            :items="backend_available" 
            v-model="backend_select"
            item-text="text"
            item-value="id"
            label="连接后端"
          />

          <v-text-field v-model="receiveSeparator" label="接收消息分割字符"/>
          <v-text-field v-model="sendSeparator" label="发送消息分割字符"/>

          <v-slide-x-transition>
            <div v-show="backend_select == 0">
              <v-text-field v-model="baudRate" label="波特率"/>
            </div>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <div v-show="backend_select == 1">
              <v-text-field v-model="serviceUuid" label="Service UUID"/>
              <v-text-field v-model="characteristicUuid" label="characteristic UUID"/>
            </div>
          </v-slide-x-transition>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = !dialog"> 取消 </v-btn>
          <v-btn color="primary" text @click="start_connection"> 连接 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import btconsole from './components/console.vue';
import Carcontrol from './components/control.vue';
import Varibles from './components/varibles.vue';
import Picture from './components/picture.vue';
import Datatransfer from './components/datatransfer.vue';

const {webserialConnect} = require("./connection/webserial")
const bluetoothConnect = require("./connection/bluetooth")

export default {
  name: 'App',

  components: {
    btconsole,
    Varibles,
    Carcontrol,
    Picture,
    Datatransfer
  },

  data: () => ({
    backend_available:[
      {
        text:"WebSerial API",
        id:0
      },
      {
        text:"Bluetooth API",
        id:1
      }
    ],
    conn_backend: null,
    port_status:false,
    snackbar:false,
    snackbar_text:"",
    // message_history:[[10000001,true,'S1000|'],[10000120321,true,'S1000|'],[1000021321312,true,'S1000|'],[100000012313,false,'status:aaa|']],
    message_history:[],
    image_data:"",
    // vars:[1,2,3,4,1.1],
    var_msg:"",
    vars_dict:{},
    dialog:false,
    backend_select:0,
    receiveSeparator:"|",
    sendSeparator:"",
    baudRate:115200,
    serviceUuid: "0xFFE0",
    characteristicUuid: "0xFFE1",
  }),

  mounted:function(){
    let json = window.localStorage.getItem("connect_args")
    if(json != null){
      let args_dict = JSON.parse(json)
      this.backend_select = args_dict.backend_select
      this.receiveSeparator = args_dict.receiveSeparator
      this.sendSeparator = args_dict.sendSeparator
      this.baudRate = args_dict.baudRate
      this.serviceUuid = args_dict.serviceUuid
      this.characteristicUuid = args_dict.characteristicUuid
    }
    
  },

  computed:{
    connect_args_dict:function(){
      return {
        backend_select: this.backend_select,
        receiveSeparator: this.receiveSeparator,
        sendSeparator: this.sendSeparator,
        baudRate: this.baudRate,
        serviceUuid: this.serviceUuid,
        characteristicUuid: this.characteristicUuid
      }
    }
  },

  methods:{
    connect_btn:function(){
      if(!this.port_status){
        this.dialog = true
      }
      else{
        this.conn_backend.disconnect()
        this.port_status = false
        this.raiseNote("设备已断开。")
      }
    },
    start_connection:function(){
      this.dialog = false
      if(this.backend_select == 0){
        this.conn_backend = new webserialConnect(
          this.receiveSeparator,
          this.sendSeparator,
          this.baudRate
        )
      }
      else if(this.backend_select == 1){
        this.conn_backend = new bluetoothConnect(
          this.serviceUuid,
          this.characteristicUuid,
          this.receiveSeparator,
          this.sendSeparator,
        )
      }

      this.conn_backend.receive = this.process_message
      //save connection args.

      window.localStorage.setItem("connect_args",JSON.stringify(this.connect_args_dict))

      this.conn_backend.connect()
        .then(() => {
          this.port_status = true
        })
        .catch((err)=>{
          this.raiseNote("设备连接失败！"  + err)
        })
    },
    process_message: function(msg){
      let msg_segments = msg.split(":")

      //history format:[timestamp,isSEND,msg]
      if(msg_segments[0] != "image"){
        this.message_history.push([Date.now(),false,msg])
      }
      else{
        this.message_history.push([Date.now(),false,"image:......."])
        this.image_data = msg_segments[1]
      }

      if(this.message_history.length > 100){
        this.message_history.shift()
      }
      
      if(msg_segments[0].split("=")[0] == "status"){
        this.var_msg = msg
      }
    },
    update_var:function(vars){
      console.log(vars)
      this.vars_dict = vars
    },
    send_message: function(msg){
      this.message_history.push([Date.now(),true,msg])
      this.conn_backend.send(msg)
      .catch(e => {
        this.raiseNote("发送指令错误。蓝牙连接上了么？" + e)
      })
      
    },
    raiseNote: function(msg){
      this.snackbar_text = msg
      this.snackbar = true
    }
  }
};
</script>
