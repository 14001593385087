<template>
  <v-card>
      <div v-for="hlvar in highlight_vars_reselect" :key="hlvar.varname">
        <highlightvar :hlvar="hlvar"/>
        <v-divider></v-divider>
      </div>
    <v-data-table 
      dense 
      :headers="header"
      :items="var_table"
      :items-per-page="10000"
      hide-default-footer
      item-key="varname"
      v-model="highlight_vars"
      
      show-select >
    </v-data-table>
  </v-card>
</template>

<script>
import highlightvar from './highlightvar.vue';
export default {
  components: { highlightvar },
  props: {
    var_msg: String,
  },
  data: () => ({
    highlight_vars: [],
    var_name_dict: {},
    dialog:false,
    printf_text:"",
    header:[
      {text:"变量名",value:"varname"},
      {text:"变量值",value:"varvalue"},
    ]
  }),
  methods: {

  },
  mounted:function(){

  },
  computed:{
    highlight_vars_reselect:function(){
      var result = []
      this.highlight_vars.forEach(element => {
        result.push({varname:element.varname,varvalue:this.var_name_dict[element.varname]})
      });
      return result
    },
    var_table:function(){
      var result = []
      for (const key in this.var_name_dict) {
        if (Object.hasOwnProperty.call(this.var_name_dict, key)) {
          const element = this.var_name_dict[key];
          result.push({"varname":key,"varvalue":element})
        }
      }
      return result
    }
  },
  watch:{
    var_msg:function(){
        let result_dict = {}
        //status=count:a=xxx,b=xxx,....
        this.var_msg.split(":")[1].split(",").forEach(segment => {
          let key_value = segment.split("=")
          if(key_value.length != 2){
            return
          }
          result_dict[key_value[0]] = key_value[1];
        });
        this.var_name_dict = result_dict
        this.$emit("update",result_dict)
    }
  }
};
</script>

<style>
  .no-data{
    text-align: center;
    color:gray;
    padding:10px;
    font-size:90%;
  }
</style>